import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import DateRange from '@/components/DateRange/DateRange.vue'
import { GlobalControl } from '@/classes/stats/GlobalControl.js'
import CampaignService from '@/services/campaign.service'
import { ScheduledReport } from '@/models/ScheduledReport.js'
import ScheduledReportsService from '@/services/scheduled-reports.service'
import TrackingEventsService from '@/services/tracking-events.service'
import StatsService from '@/services/stats.service'
import vMultiselectListbox from 'vue-multiselect-listbox'
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css'
import moment from 'moment'
import EventBus from '@/util/EventBus'

export default {
  name: 'Custom',
  components: {
    HeaderTopDashboard,
    DateRange,
    vMultiselectListbox,
  },
  data () {
    return {
      globalControl: new GlobalControl(),
      globalControlReports: new GlobalControl(),
      campaigns: [],
      options: {},
      content: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      totalReports: 0,
      debouncedInput: '',
      timeout: null,
      searchTerm: '',
      loading: false,
      showModalAutomateReport: false,
      defaultEmails: [],
      selectedEmails: [],
      defaultManualEmails: [],
      selectedManualEmails: [],
      searchEmails: null,
      searchManualEmails: null,
      report: new ScheduledReport(),
      editReport: new ScheduledReport(),
      showModalReportEventsColumns: false,
      loadedColumns: false,
      trackingEvents: [],
      customizeFields: false,
      customFields: [],
      defaultEventPrefix: 'events',
      eventPrefix: '',
      removeReportDialog: false,
      columns: [],
      trackingEventsSource: [],
      sendReportDialog: false,
      sendReportId: undefined,
      tableAction: null,
    }
  },
  computed: {
    channels () {
      return [
        { text: this.$t('Seleccionar canal'), value: 0 },
        { text: 'SMS', value: 1 },
        { text: 'Mailing', value: 2 },
        { text: this.$t('Voz'), value: 7 },
      ]
    },
    frequencies () {
      return [
        { text: this.$t('Diario'), value: 'daily' },
        { text: this.$t('Semanal'), value: 'weekly' },
        { text: this.$t('Quincenal'), value: 'semimonthly' },
        { text: this.$t('Mensual'), value: 'monthly' },
      ]
    },
    formats () {
      return [
        { text: 'CSV', value: 'csv' },
        { text: 'PDF', value: 'pdf' },
      ]
    },
    icon () {
      if (this.allCampaignsSelected) return 'fas fa-times'
      if (this.someCampaignsSelected) return 'fa fa-minus'
      return 'far fa-square'
    },
    allCampaignsSelected () {
      return this.report.campaigns.length === this.campaigns.length
    },
    someCampaignsSelected () {
      return this.report.campaigns.length > 0 && !this.allCampaignsSelected
    },
    params (nv) {
      return {
        ...this.options,
        query: this.searchTerm,
      }
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
    headers () {
      return [
        {
          text: this.$t('Informe'),
          value: 'name',
        },
        {
          text: this.$t('Canal'),
          value: 'channelTypeId',
        },
        {
          text: this.$t('Tipo'),
          value: 'type',
        },
        {
          text: this.$t('Fecha de creación'),
          value: 'createdAt',
        },
        {
          text: this.$t('Actualización'),
          value: 'updatedAt',
        },
        {
          text: this.$t('Periodo del envío'),
          value: 'frequency',
        },
        {
          text: this.$t('Envíos'),
          value: 'totalSent',
        },
        {
          text: this.$t('Acciones'),
          value: 'actions',
        },
      ]
    },
  },
  watch: {
    params: {
      handler () {
        this.setParamsAndGetData()
      },
      deep: true,
    },
    showModalReportEventsColumns (val) {
      if (!val) {
        setTimeout(() => {
          this.resetReport()
        }, 700)
      }
    },
    'report.channelTypeId': {
      handler () {
        if (!this.report.channelTypeId) {
          this.campaigns = []
        }
      },
      deep: true,
    },
    'report.campaigns': {
      handler () {
        StatsService.getCustomFieldsFromCampaignIds({ campaignIds: this.getSelectedCampaignIds(this.report) })
        .then((response) => {
          this.customFields = response.customFields
          this.addCustomFieldsToColumns()
        })
      },
      deep: true,
    },
  },
  mounted () {
    this.resetReport()
  },
  methods: {
    getMinimumDate () {
      return moment(new Date()).format('YYYY-MM-DDTHH:mm')
    },

    getSelectedCampaignIds (report) {
      return report.campaigns.map((campaign) => { return campaign.id })
    },
    // eslint-disable-next-line complexity
    setParamsAndGetData () {
      const page = this.tableAction === 'DELETED' && this.content.length === 1 && this.options.page > 1
        ? this.options.page - 1
        : this.options.page
      this.tableAction = null
      const params = {
        page: page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        initDate: this.globalControlReports.initDate,
        endDate: this.globalControlReports.endDate,
      }

      for (let i = 0; i < this.options.sortBy.length; i++) {
        params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
        params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
          ? 'asc'
          : 'desc'
      }
      this.content = []
      ScheduledReportsService.getReports(params).then((response) => {
        this.itemsPerPage = parseInt(response.per_page)
        this.page = response.current_page
        this.totalReports = response.total

        response.data.forEach(report => {
          this.content.push(new ScheduledReport(report))
        })
      })
    },
    resetReport () {
      this.report = new ScheduledReport()
      this.report.dateInit = this.globalControl.initDate
      this.report.dateEnd = this.globalControl.endDate
      this.customizeFields = false
      this.toggleColumns()
    },

    getFrequencyText (value) {
      if (!value) return
      const found = this.frequencies.filter(function (frequency) { return frequency.value === value })
      return found[0].text
    },

    formatDate (date) {
      return moment(date).format('DD-MM-YYYY')
    },

    removeEmail (removeEmail) {
      this.editReport.sendToEmails = this.editReport.sendToEmails.filter(function (email) {
        return removeEmail !== email
      })
    },

    removeManualEmail (removeEmail) {
      this.editReport.sendToManualEmails = this.editReport.sendToManualEmails.filter(function (email) {
        return removeEmail !== email
      })
    },

    refreshReport () {
      this.setParamsAndGetData()
      this.showModalAutomateReport = false
      this.showModalReportEventsColumns = false
      setTimeout(() => {
        this.resetReport()
      }, 700)
    },

    newReport () {
      const reportData = JSON.parse(JSON.stringify(this.report))
      if (reportData.id) {
        ScheduledReportsService.edit({ report: reportData }).then(() => {
          this.refreshReport()
        })
      } else {
        reportData.campaigns = this.getSelectedCampaignIds(reportData)
        ScheduledReportsService.save({ report: reportData }).then(() => {
          this.refreshReport()
        })
      }
    },

    loadAutomateReport (report) {
      this.editReport = report
      if (!this.editReport.sendToEmails.length) {
        const user = JSON.parse(localStorage.getItem('user'))
        this.editReport.sendToEmails = [user.email]
      }
      this.showModalAutomateReport = true
    },

    automateReport () {
      const reportData = JSON.parse(JSON.stringify(this.editReport))
      reportData.type = 'automatic'
      const dateTimeInitSend = moment(reportData.dateTimeInitSend)

      switch (reportData.frequency) {
        case 'daily':
          dateTimeInitSend.add(1, 'days')
          break
        case 'weekly':
          dateTimeInitSend.add(7, 'days')
          break
        case 'semimonthly':
          dateTimeInitSend.add(15, 'days')
          break
        case 'yearly':
          dateTimeInitSend.add(1, 'years')
          break
      }

      reportData.nextSendAt = dateTimeInitSend.format('YYYY-MM-DD HH:mm:ss')
      ScheduledReportsService.edit({ report: reportData }).then(() => {
        this.showModalAutomateReport = false
        this.setParamsAndGetData()
      })
    },

    getTypeTranslation (type) {
      return type === 'automatic' ? this.$t('Automático').toString() : this.$t('Básico').toString()
    },

    getCampaigns () {
      if (!this.report.channelTypeId) return
      const params = {
        channelTypeId: this.report.channelTypeId,
        initDate: this.report.dateInit,
        endDate: this.report.dateEnd,
      }

      CampaignService.getByChannel(params).then((campaigns) => {
        this.campaigns = campaigns
      })
    },
    selectDates (dates) {
      this.report.dateInit = dates[0]
      this.report.dateEnd = dates[1]
      this.getCampaigns()
    },
    selectDatesReports (dates) {
      this.globalControlReports.initDate = dates[0]
      this.globalControlReports.endDate = dates[1]
      this.setParamsAndGetData()
    },
    toggle () {
      this.$nextTick(() => {
        if (this.allCampaignsSelected) {
          this.report.campaigns = []
        } else {
          this.report.campaigns = this.campaigns.slice()
        }
      })
    },
    setSmsColumns () {
      this.columns = [
        { text: this.$t('Nombre').toString(), value: 'contactName' },
        { text: this.$t('Teléfono Móvil').toString(), value: 'contactPhone' },
        { text: 'Email', value: 'contactEmail' },
        { text: this.$t('Teléfono Fijo').toString(), value: 'contactLandLine' },
        { text: this.$t('Evento').toString(), value: 'event' },
        { text: this.$t('País').toString(), value: 'countryIso' },
        { text: this.$t('Fecha evento').toString(), value: 'createdAt' },
        { text: this.$t('External id').toString(), value: 'externalId' },
        { text: this.$t('Mensaje').toString(), value: 'message' },
        { text: this.$t('URL').toString(), value: 'URL' },
      ]
      this.addCustomFieldsToColumns()
    },

    setMailingColumns () {
      this.columns = [
        { text: this.$t('Nombre').toString(), value: 'contactName' },
        { text: this.$t('Teléfono Móvil').toString(), value: 'contactPhone' },
        { text: 'Email', value: 'contactEmail' },
        { text: this.$t('Teléfono Fijo').toString(), value: 'contactLandLine' },
        { text: this.$t('Asunto').toString(), value: 'subject' },
        { text: this.$t('Remitente').toString(), value: 'from' },
        { text: this.$t('Replica').toString(), value: 'reply' },
        { text: this.$t('Evento').toString(), value: 'event' },
        { text: this.$t('País').toString(), value: 'countryIso' },
        { text: this.$t('Fecha evento').toString(), value: 'createdAt' },
        { text: this.$t('External id').toString(), value: 'externalId' },
        { text: this.$t('Browser').toString(), value: 'Browser' },
        { text: this.$t('OS').toString(), value: 'OS' },
        { text: 'IP', value: 'IP' },
        { text: this.$t('URL').toString(), value: 'URL' },
      ]
      this.addCustomFieldsToColumns()
    },

    setVoiceColumns () {
      this.columns = [
        { text: this.$t('Nombre').toString(), value: 'contactName' },
        { text: this.$t('Teléfono Móvil').toString(), value: 'contactPhone' },
        { text: 'Email', value: 'contactEmail' },
        { text: this.$t('Teléfono Fijo').toString(), value: 'contactLandLine' },
        { text: this.$t('Remitente').toString(), value: 'from' },
        { text: this.$t('Evento').toString(), value: 'event' },
        { text: this.$t('País').toString(), value: 'country_iso' },
        { text: this.$t('Fecha evento').toString(), value: 'created_at' },
        { text: this.$t('External id').toString(), value: 'external_id' },
        { text: this.$t('Mensaje').toString(), value: 'message' },
        { text: this.$t('Duración (segundos)').toString(), value: 'duration' },
      ]
      this.addCustomFieldsToColumns()
    },

    setInverseSmsColumns () {
      this.trackingEvents = this.reorderEvents(
        this.trackingEvents.concat([
          { id: 90, event: 'pending' },
          { id: 91, event: 'no_clicks' },
        ]), [
        'sent', 'delivered', 'undelivered', 'clicked', 'no_clicks', 'pending', 'unsubscribed',
      ])
    },

    setInverseMailingColumns () {
      this.trackingEvents = this.reorderEvents(
        this.trackingEvents.concat([
          { id: 92, event: 'not_received' },
          { id: 93, event: 'unopened' },
          { id: 91, event: 'no_clicks' },
        ]), [
        'sent', 'delivered', 'not_received', 'opened', 'unopened',
        'clicked', 'no_clicks', 'soft_bounced', 'hard_bounced', 'unsubscribed',
      ])
    },

    setInverseVoiceColumns () {
      this.trackingEvents = this.reorderEvents(
        this.trackingEvents.concat([
          { id: 94, event: 'unattended' },
        ]), [
        'sent', 'delivered', 'unattended', 'expired', 'unsubscribed',
      ])
    },

    setDefaultColumnsByChannel () {
      switch (this.channel) {
        case 'sms':
          this.report.columns = ['contactName', 'contactPhone', 'contactEmail', 'contactLandLine', 'event', 'countryIso', 'createdAt', 'externalId', 'message', 'URL']
          break
        case 'mailing':
          this.report.columns = ['contactName', 'contactPhone', 'contactEmail', 'contactLandLine', 'subject', 'from', 'reply', 'event', 'countryIso', 'createdAt', 'externalId', 'Browser', 'OS', 'IP', 'URL']
          break
        case 'voice':
          this.report.columns = ['contactName', 'contactPhone', 'contactEmail', 'contactLandLine', 'from', 'event', 'country_iso', 'created_at', 'external_id', 'message', 'duration']
          break
      }
    },

    reorderEvents (arr, index) {
      const temp = [...arr]
      for (let i = 0; i < arr.length; i++) {
        temp[i] = arr[arr.findIndex(e => e.event === index[i])]
      }
      for (let i = 0; i < arr.length; i++) {
        arr[i] = temp[i]
      }

      return arr
    },

    toggleColumns () {
      if (this.customizeFields) {
        this.report.columns = []
      } else {
        this.setColumnsByChannel()
      }
    },
    setColumnsByChannel () {
      this.trackingEvents = [...this.trackingEventsSource]
      this.report.events = this.trackingEventsSource.map(function (element) {
        return element.id
      })
      switch (this.channel) {
        case 'sms':
          this.setSmsColumns()
          this.setInverseSmsColumns()
          break
        case 'mailing':
          this.setMailingColumns()
          this.setInverseMailingColumns()
          break
        case 'voice':
          this.setVoiceColumns()
          this.setInverseVoiceColumns()
          break
      }
      this.setDefaultColumnsByChannel()
    },

    getChannelTranslated (channelId) {
      let channel = ''
      switch (channelId) {
        case 1:
          channel = 'Sms'
          break
        case 2:
          channel = this.$t('Mailing').toString()
          break
        case 7:
          channel = this.$t('Voz').toString()
          break
      }
      return channel
    },

    getChannelString (channelId = null) {
      let channel = ''
      channelId = channelId || this.report.channelTypeId
      switch (channelId) {
        case 1:
          channel = 'sms'
          break
        case 2:
          channel = 'mailing'
          break
        case 7:
          channel = 'voice'
          break
      }
      return channel
    },

    addCustomFieldsToColumns () {
      this.customFields.forEach(field => {
        this.columns.push({ text: field.text, value: field.value })
      })
    },

    commonPrepareReportDetails () {
      this.loadedColumns = false
      this.channel = this.getChannelString()
      this.eventPrefix = this.defaultEventPrefix + '.' + this.channel + '.'
      TrackingEventsService.getEventsByChannel(this.channel)
      .then(
        (response) => {
          this.trackingEventsSource = response.events
          this.setColumnsByChannel()
          this.loadedColumns = true
          this.showModalReportEventsColumns = true
        },
        () => {},
      )
      .finally(() => {
        this.loading = false
      })
      .finally(() => (this.loading = false))
    },

    prepareReportDetails () {
      this.commonPrepareReportDetails()
    },

    prepareEditReportDetails (report) {
      this.report = report
      this.commonPrepareReportDetails()
    },

    confirmRemoveReport (report) {
      this.editReport = report
      this.removeReportDialog = true
    },

    removeReport () {
      ScheduledReportsService.removeReport({ id: this.editReport.id }).then(() => {
        this.tableAction = 'DELETED'
        this.setParamsAndGetData()
        this.removeReportDialog = false
      }).catch(() => { })
    },
    prepareSendReport (item) {
      this.editReport = item
      if (!this.editReport.sendToManualEmails.length) {
        const user = JSON.parse(localStorage.getItem('user'))
        this.editReport.sendToManualEmails = [user.email]
      }
      this.sendReportDialog = true
    },
    closeSendReportDialog () {
      this.sendReportDialog = false
    },
    sendReport () {
      ScheduledReportsService.sendReport({ id: this.editReport.id, sendToManualEmails: this.editReport.sendToManualEmails })
        .then(() => {
          this.closeSendReportDialog()
          EventBus.$emit('showAlert', 'success', this.$t('El reporte se enviará a tu correo electrónico una vez esté listo'))
        })
        .catch(() => { })
        .finally(() => {
          this.sendReportDialog = false
        })
    },

  },
  created () { },
}
