import { Service } from './service'

const PATH = 'tracking/'
const SHARE_PATH = 'share/tracking/'

class TrackingEventsService extends Service {
  getEvents () {
    return this.get(PATH + 'get-events')
  }

  getEventsByChannel (channel) {
    return this.get(PATH + 'get-events?channel=' + channel)
  }

  getShareEvents (shareHash) {
    return this.get(SHARE_PATH + 'get-events?shareHash=' + shareHash)
  }
}

export default new TrackingEventsService()
