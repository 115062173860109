import { Service } from './service'

const PATH = 'reports/'

class ScheduledReportsService extends Service {
  save (params) {
    return this.post(PATH + 'save', params)
  }

  getReports (params) {
    return this.get(PATH + 'reports', params)
  }

  edit (params) {
    return this.post(PATH + 'edit', params)
  }

  removeReport (params) {
    return this.post(PATH + 'delete', params)
  }

  sendReport (params) {
    return this.post(PATH + 'send-report', params)
  }
}

export default new ScheduledReportsService()
